import React, { Component } from "react";
import { fetchRandomName } from "./services/DataService";
import { GiBeerBottle } from "react-icons/gi";
import { FiShare2 } from "react-icons/fi";
import {
  Facebook,
  Twitter,
  Pinterest,
  Reddit,
  Tumblr
} from "react-sharingbuttons";
import "react-sharingbuttons/dist/main.css";
import "./style/App.scss";
import "./style/randomname.scss";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isAdjectiveLocked: false,
      isNounLocked: false
    };
    this.regenerate = this.regenerate.bind(this);
  }

  componentDidMount() {
    this.regenerate();
  }

  regenerate() {
    if (this.state.isAdjectiveLocked && this.state.isNounLocked) {
      return;
    }
    this.setState({ loading: true });
    fetchRandomName(r => {
      let a = this.state.isAdjectiveLocked
        ? this.state.adjective
        : r.split(" ")[0];
      let n = this.state.isNounLocked ? this.state.noun : r.split(" ")[1];
      this.setState({
        randomName: r,
        adjective: a,
        noun: n,
        loading: false
      });
    });
  }

  render() {
    let url = "https://givemea.beer";
    let shareText = `How's a nice tall glass of "${this.state.adjective} ${this.state.noun}" sound? Come to https://givemea.beer and find the name of your next beer!`;
    let button = (
      <button
        name="test"
        className="btn btn-3 btn-3e test"
        onClick={this.regenerate}
      >
        BREW <GiBeerBottle className="bottle" />
      </button>
    );
    if (this.state.loading) {
      button = (
        <button name="test" className="btn btn-3 btn-3e test disabled">
          BREW <GiBeerBottle className="bottle" />
        </button>
      );
    }
    return (
      <div className="App">
        <div className="givemeabeer-header">
          <img
            src="https://witstore.blob.core.windows.net/assets/images/taop.png"
            className="App-logo wobble"
            alt="logo"
          />
          <div className="App-title">Give me a beer!</div>
        </div>
        <div id="results">
          <div id="adjective-container">
            <div id="adjective-word">{this.state.adjective}</div>
            <div
              className={
                this.state.isAdjectiveLocked
                  ? "icon-lock adjective-lock locked"
                  : "icon-lock adjective-lock"
              }
              onClick={() => {
                this.setState({
                  isAdjectiveLocked: !this.state.isAdjectiveLocked
                });
              }}
            ></div>
          </div>
          <div id="noun-container">
            <div id="noun-word">{this.state.noun}</div>
            <div
              className={
                this.state.isNounLocked
                  ? "icon-lock noun-lock locked"
                  : "icon-lock noun-lock"
              }
              onClick={() => {
                this.setState({
                  isNounLocked: !this.state.isNounLocked
                });
              }}
            ></div>
          </div>
        </div>
        <div className="btn-container">{button}</div>
        <div className="share-container">
          <FiShare2 className="share-icon" />
          <Facebook url={url} />
          <Twitter url={url} shareText={shareText} />
          <Pinterest url={url} shareText={shareText} />
          <Reddit url={url} />
          <Tumblr
            url={url}
            title={this.state.adjective + " " + this.state.noun + " 🍺"}
            caption={shareText}
          />
        </div>
      </div>
    );
  }
}

export default App;
