let fetchRandomName = callback => {
  fetch(process.env.REACT_APP_API_URL + "/random/name")
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

let fetchStyles = callback => {
  fetch(process.env.REACT_APP_API_URL + "/styles")
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

let fetchFermentable = (id, callback) => {
  fetch(process.env.REACT_APP_API_URL + "/fermentables/" + id)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

let fetchFermentables = callback => {
  fetch(process.env.REACT_APP_API_URL + "/fermentables")
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

let fetchHop = (id, callback) => {
  fetch(process.env.REACT_APP_API_URL + "/hops/" + id)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

let fetchHops = callback => {
  fetch(process.env.REACT_APP_API_URL + "/hops")
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

let fetchYeast = (id, callback) => {
  fetch(process.env.REACT_APP_API_URL + "/yeast/" + id)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

let fetchYeasts = callback => {
  fetch(process.env.REACT_APP_API_URL + "/yeast")
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

let fetchMisc = (id, callback) => {
  fetch(process.env.REACT_APP_API_URL + "/misc/" + id)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

let fetchMiscs = callback => {
  fetch(process.env.REACT_APP_API_URL + "/misc")
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

let fetchRecipe = (id, callback) => {
  let url = process.env.REACT_APP_API_URL + "/recipes/" + id;
  fetch(url, {
    method: "GET"
    // headers: {
    //   Authorization: "Bearer " + window.localStorage.getItem("id_token")
    // }
  })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

let fetchCategories = callback => {
  fetch(process.env.REACT_APP_API_URL + "/categories")
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(json => {
      return json.result;
    })
    .then(callback)
    .catch(_errorHandler);
};

let putRecipe = (recipe, callback) => {
  fetch(process.env.REACT_APP_API_URL + "/recipes", {
    method: "PUT",
    // headers: {
    //   Authorization: "Bearer " + window.localStorage.getItem("id_token")
    // },
    body: JSON.stringify(recipe)
  })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

let postRecipe = (recipe, callback) => {
  fetch(process.env.REACT_APP_API_URL + "/recipes", {
    method: "POST",
    // headers: {
    //   Authorization: "Bearer " + window.localStorage.getItem("id_token")
    // },
    body: JSON.stringify(recipe)
  })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

let deleteRecipe = (id, callback) => {
  fetch(process.env.REACT_APP_API_URL + "/recipes/" + id, {
    method: "DELETE"
    // headers: {
    //   Authorization: "Bearer " + window.localStorage.getItem("id_token")
    // }
  })
    .then(response => {
      if (response.status === 204) {
        return;
      }
      throw new Error("Network response failed.");
    })
    .then(callback)
    .catch(_errorHandler);
};

let _errorHandler = error => {
  // window.localStorage.clear();
  // window.location.href = "/";
  console.error("Fetch error: ", error.message);
};

export {
  fetchRandomName,
  fetchStyles,
  fetchFermentable,
  fetchFermentables,
  fetchHop,
  fetchHops,
  fetchYeast,
  fetchYeasts,
  fetchMisc,
  fetchMiscs,
  fetchRecipe,
  fetchCategories,
  putRecipe,
  postRecipe,
  deleteRecipe
};
